const lerp = (a, b, n) => (1 - n) * a + n * b

const getMousePos = e => {
  let posx = 0
  let posy = 0
  if (!e) e = window.event
  if (e.clientX || e.clientY) {
    posx = e.clientX
    posy = e.clientY
  }
  return {
    x: posx,
    y: posy,
  }
}

// Custom mouse cursor.
class CursorFx {
  constructor(el) {
    this.DOM = {
      el: el,
    }
    this.DOM.dot = this.DOM.el.querySelector('.cursor__inner--dot')
    this.DOM.circle = this.DOM.el.querySelector('.cursor__inner--circle')
    this.DOM.spanInFilled = this.DOM.el.querySelector('.cursor__inner--dot div')
    this.bounds = {
      dot: this.DOM.dot.getBoundingClientRect(),
      circle: this.DOM.circle.getBoundingClientRect(),
    }
    this.scale = 1
    this.circleScale = 1
    this.spanInFilledScale = 1
    this.opacity = 1
    this.mousePos = {
      x: 0,
      y: 0,
    }
    this.lastMousePos = {
      dot: {
        x: 0,
        y: 0,
      },
      circle: {
        x: 0,
        y: 0,
      },
    }
    this.lastScale = 1
    this.lastCircleScale = 1
    this.lastOpacity = 1

    this.initEvents()
    requestAnimationFrame(() => this.render())
  }
  initEvents() {
    window.addEventListener(
      'mousemove',
      ev => (this.mousePos = getMousePos(ev))
    )
  }
  hide() {
    this.DOM.circle.style.visibility = 'hidden'
  }

  show() {
    this.DOM.dot.style.visibility = 'visible'
    this.DOM.circle.style.visibility = 'visible'
  }
  /*changeMousePos() {
        this.lastMousePos.dot.x = lerp(this.lastMousePos.dot.x, this.mousePos.x - this.bounds.dot.width/2, 1);
        this.lastMousePos.dot.y = lerp(this.lastMousePos.dot.y, this.mousePos.y - this.bounds.dot.height/2, 1);
        this.lastMousePos.circle.x = lerp(this.lastMousePos.circle.x, this.mousePos.x - this.bounds.circle.width/2, 0.15);
        this.lastMousePos.circle.y = lerp(this.lastMousePos.circle.y, this.mousePos.y - this.bounds.circle.height/2, 0.15);
    }*/
  changeMousePos() {
    // console.log("change mouse pos: ", this.scale)
    // console.log("last scale", this.lastScale)
    //console.log("last scale", this.lastScale, "curr scale", this.scale
    if (this.lastScale === 1 || this.scale === 1) {
      //console.log("condition 1")
      this.lastMousePos.dot.x = lerp(
        this.lastMousePos.dot.x,
        this.mousePos.x - (this.bounds.dot.width * this.lastScale) / 2,
        1
      )
      this.lastMousePos.dot.y = lerp(
        this.lastMousePos.dot.y,
        this.mousePos.y - (this.bounds.dot.height * this.lastScale) / 2,
        1
      )
    } else {
      //console.log("condition 2")
      this.lastMousePos.dot.x = lerp(
        this.lastMousePos.dot.x,
        this.mousePos.x - (this.bounds.dot.width * this.lastScale) / 2,
        1
      )
      this.lastMousePos.dot.y = lerp(
        this.lastMousePos.dot.y,
        this.mousePos.y - (this.bounds.dot.height * this.lastScale) / 2,
        1
      )
    }
    if (this.lastScale === 1 || this.scale === 1) {
      //console.log("condition 1")
      this.lastMousePos.circle.x = lerp(
        this.lastMousePos.circle.x,
        this.mousePos.x - (this.bounds.circle.width * this.lastScale) / 2,
        0.15
      )
      this.lastMousePos.circle.y = lerp(
        this.lastMousePos.circle.y,
        this.mousePos.y - (this.bounds.circle.height * this.lastScale) / 2,
        0.15
      )
    } else {
      //console.log("condition 2")
      this.lastMousePos.circle.x = lerp(
        this.lastMousePos.circle.x,
        this.mousePos.x - (this.bounds.circle.width * this.lastScale) / 2,
        0.15
      )
      this.lastMousePos.circle.y = lerp(
        this.lastMousePos.circle.y,
        this.mousePos.y - (this.bounds.circle.height * this.lastScale) / 2,
        0.15
      )
    }
  }
  changeScaling() {
    this.lastScale = lerp(this.lastScale, this.scale, 0.15)
    this.lastCircleScale = lerp(this.lastCircleScale, this.circleScale, 0.15)
  }
  changeOpacity() {
    this.lastOpacity = lerp(this.lastOpacity, this.opacity, 0.1)
  }
  handleScaling() {
    this.newCircleWidth = this.bounds.circle.width * this.lastScale + 'px'
    this.newCircleHeight = this.bounds.circle.height * this.lastScale + 'px'

    this.DOM.circle.style.width = this.newCircleWidth
    this.DOM.circle.style.height = this.newCircleHeight

    // if (this.hoverText) {
    //   this.newDotWidth = this.newCircleWidth
    //   this.newDotHeight = this.newCircleHeight
    // } else {
    this.newDotWidth = this.bounds.dot.width * this.lastScale + 'px'
    this.newDotHeight = this.bounds.dot.height * this.lastScale + 'px'
    // }

    this.DOM.dot.style.width = this.newDotWidth
    this.DOM.dot.style.height = this.newDotHeight
  }

  handleTransforms() {
    this.DOM.dot.style.transform = `translateX(${this.lastMousePos.dot.x}px) translateY(${this.lastMousePos.dot.y}px) translateZ(0)`
    this.DOM.circle.style.transform = `translateX(${this.lastMousePos.circle.x}px) translateY(${this.lastMousePos.circle.y}px) translateZ(0)`
  }

  handleStyles() {
    this.handleTransforms()
    this.handleScaling()
    this.DOM.circle.style.opacity = this.lastOpacity
  }
  render() {
    this.show()
    this.changeMousePos()
    this.changeScaling()
    this.changeOpacity()
    this.handleStyles()

    requestAnimationFrame(() => this.render())
  }
  enterDataHover(color) {
    //this.scale = 2.7
    //this.DOM.dot.style.display = "none"
    /* altered styling: want inner dot to expand to cirlce on hover */
    this.scale = 4
    this.DOM.dot.style.background = 'none'
    this.DOM.dot.style.border = color
      ? `2px solid ${color}`
      : '2px solid #ff6f75'
  }
  leaveDataHover() {
    this.scale = 1
    this.DOM.dot.style.display = 'flex'
    /* altered styling: want inner dot to expand to cirlce on hover */
    this.DOM.dot.style.background = '#ff6f75'
    this.DOM.dot.style.border = 'none'
    /* new cursor: cursor is circle that expands on hover */
  }
  clickDataHover() {
    this.lastScale = 1
    this.lastOpacity = 0
    this.scale = 5
    this.DOM.dot.style.display = 'flex'
  }

  enterLargeFilled(text) {
    this.scale = 6
    this.DOM.spanInFilled.innerHTML = text
  }
  leaveLargeFilled() {
    this.scale = 1
    //this.DOM.circle.style.display = "block" /* circle removed */
    this.DOM.spanInFilled.innerHTML = ''
  }
  clickLargeFilled() {
    this.lastScale = 4
  }
  disappearOnClick() {
    this.DOM.dot.style.display = 'none'
    this.DOM.circle.style.display = 'none'
  }
  enterLargeText(text, color) {
    document.body.style.cursor = 'none'
    this.enterLargeFilled(text)
    this.hoverText = true
    this.DOM.dot.style.display = 'block'
    //this.DOM.circle.style.display = "block"
    //this.DOM.dot.style.background = '#ff6f75'
    this.DOM.dot.style.background = 'white'
    //this.DOM.dot.style.border = "1px solid #ff6f75" /* added this with changes */
    this.DOM.spanInFilled.style.background = 'none'
    this.DOM.dot.style.mixBlendMode = 'normal'
    this.DOM.circle.style.mixBlendMode = 'normal'
    this.DOM.spanInFilled.style.color = color
    //this.DOM.circle.style.borderColor = color
    this.DOM.dot.style.borderColor = color
    this.DOM.spanInFilled.style.width = '40px' // scaled width when there is fill text
  }
  leaveLargeText() {
    this.leaveLargeFilled()
    this.hoverText = false
    this.DOM.dot.style.background = null
    this.DOM.spanInFilled.style.background = null
    this.DOM.dot.style.mixBlendMode = null
    this.DOM.circle.style.mixBlendMode = null
    this.DOM.spanInFilled.style.color = null
    //this.DOM.circle.style.borderColor = null
    this.DOM.dot.style.border = 'none' /* added this with changes */
    this.DOM.dot.style.background = '#ff6575'
    //this.DOM.dot.style.borderColor = null /* added this with changes */
    this.DOM.spanInFilled.style.width = null
  }
  disappearOnLeave() {
    document.body.style.cursor = 'auto'
    this.leaveLargeFilled()
    this.hoverText = false
    this.DOM.dot.style.display = 'none'
    this.DOM.circle.style.display = 'none'
  }
}

export default CursorFx
