import React from 'react'
import styles from './menu-button-styles.module.scss'
import classNames from 'classnames/bind'

let cx = classNames.bind(styles)

const MenuButton = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
      color: this.props.color ? this.props.color : 'black',
      white: this.props.isInverted,
    }
    //console.log('menu button is ' + this.state.open)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    let containerClass = cx('container', {
      open: this.props.open,
      white: this.props.isInverted,
    })
    let lineTopClass = cx('line', 'lineTop', {
      open: this.props.open,
      white: this.props.isInverted,
    })
    let lineMiddleClass = cx('line', 'lineMiddle', {
      open: this.props.open,
      white: this.props.isInverted,
    })
    let lineBottomClass = cx('line', 'lineBottom', {
      open: this.props.open,
      white: this.props.isInverted,
    })

    return (
      <div
        className={containerClass}
        onClick={
          this.props.onClick
            ? this.props.onClick
            : () => {
                this.handleClick()
              }
        }
      >
        <div className={lineTopClass} />
        {/* <div className={lineMiddleClass} /> */}
        <div className={lineBottomClass} />
      </div>
    )
  }
}

export default MenuButton
