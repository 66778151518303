import React from 'react'
import styles from './menu-item-styles.module.scss'
import { Link } from 'gatsby'

const MenuItem = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
    }
  }

  handleHover() {
    this.setState({ hover: !this.state.hover })
  }

  render() {
    return (
      <Link className={styles.container} to={this.props.link}>
        <div className={styles.menuItem} onClick={this.props.onClick}>
          {this.props.children}
        </div>
      </Link>
    )
  }
}

export default MenuItem
