import React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/components/desktop-header.module.scss'
import styleVars from '../styles/variables.scss'
import classNames from 'classnames/bind'

import HamburgerIcon from './menu/hamburgerIcon'

import ScrollProgressBar from '../components/scrollProgressBar'

import finchHead from '../images/finch-head.png'
import rightArrow from '../images/right-arrow-blue.png'

let cx = classNames.bind(styles)

const DesktopHeader = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scroll: 0,
      stick: false,
      show: false,
      dropShadow: false,
      isMobile: null,
      fixScrollbar: false,
    }
  }

  resize = () => {
    this.setState({
      isMobile: window.innerWidth <= parseInt(styleVars.breakpointHeader),
    })
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
    window.onscroll = () => {
      this.setState(prevState => {
        let isMobile = this.state.isMobile
        const scroll = window.scrollY
        var stick = isMobile ? scroll >= 150 : scroll >= 250
        var fixScrollbar = isMobile
          ? scroll > 50 && scroll < 150
          : scroll > 90 && scroll < 250
        //console.log(fixScrollbar)
        var show =
          (scroll <= prevState.scroll && scroll > 300) ||
          (isMobile && scroll <= prevState.scroll && scroll > 150)
        //show = show && scroll >= 30
        if (show) stick = true
        return {
          scroll: scroll,
          stick: stick,
          show: show,
          fixScrollbar: fixScrollbar,
        }
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  render() {
    const menu = [
      { id: 0, val: 'Home', link: '/' },
      { id: 1, val: 'About', link: '/about/' },
      { id: 2, val: 'Partnership Program', link: '/partnership-program/' },
      { id: 3, val: 'Case Studies', link: '/case-studies/' },
      { id: 4, val: 'Creative Lab', link: '/creative-lab/' },
      { id: 5, val: 'Contact', link: '/contact/' },
    ]

    return (
      <div
        className={cx('container', {
          stick: this.state.stick,
          show: this.state.show,
        })}
      >
        <div className={styles.finchIcon}>
          <Link to="/">
            <img src={finchHead} alt="15 Finches" />
          </Link>
        </div>
        {this.state.isMobile && <HamburgerIcon menu={menu} />}
        {!this.state.isMobile && (
          <div className={styles.navItems}>
            {menu.map((item, i) => {
              return (
                <Link to={item.link} key={i} activeStyle={{ color: '#341fff' }}>
                  {item.val}
                </Link>
              )
            })}
          </div>
        )}
        {!this.state.isMobile && (
          <div className={styles.formLink}>
            <Link to="/contact-form/">
              Let's build something awesome together!
            </Link>
            {/* <img className={styles.arrow} src={rightArrow} alt="" /> */}
          </div>
        )}
        {this.props.scrollbar && this.props.scrollRef && (
          <div
            className={cx('scrollContainer', {
              fixScrollbar: this.state.fixScrollbar,
            })}
          >
            <ScrollProgressBar article={this.props.scrollRef} />
          </div>
        )}
      </div>
    )
  }
}

export default DesktopHeader
