import React from 'react'
import { Link } from 'gatsby'
import HamburgerIcon from './menu/hamburgerIcon'
import VerticalTitle from './verticalTitle'
import styles from '../styles/components/header.module.scss'
import classNames from 'classnames/bind'
import finchHead from '../images/finch-head.png'

let cx = classNames.bind(styles)
let docHeight = 0

const Header = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      titleIsFixed: false,
      titlePosY: 0,
      menuIsFixed: false,
      menuPosY: 0,
      isInverted: this.props.isInverted
        ? this.props.pageTitle === 'CaseStudyTemplate'
        : false,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // if screen size tablet, always fixed
    if (window.innerWidth <= 768) {
      this.setState({ menuIsFixed: true })
    }
    this.setState({ titlePosY: window.innerHeight / 2 })
    docHeight = document.documentElement.offsetHeight
  }

  handleScroll() {
    if (typeof window !== `undefined`) {
      this.setState({ menuIsFixed: false })

      var winHeight = window.innerHeight
      //console.log('winHeight' + winHeight)
      // Annoying to compute doc height due to browser inconsistency
      var body = document.body
      var html = document.documentElement
      var docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      )

      var value = window.scrollY
      //var value = document.docusmentElement.scrollTop || document.body.scrollTop
      //console.log('value ' + value)
      var max, percent
      max = docHeight - winHeight
      percent = (value / max) * 100

      //console.log('percent scroll ' + percent)

      let halfWindowHeight = 0.5 * winHeight
      //console.log('halfWindowHeight ' + halfWindowHeight)
      //this.setState({value >= halfWindowHeight ? {isFixed:true} : {isFixed: false} })

      // vertical offset from top of title is 33px plus length 50px
      if (value >= halfWindowHeight - 83 || window.innerWidth <= 768) {
        this.setState({
          titleIsFixed: true,
          titlePosY: window.innerWidth <= 768 ? 50 : 83,
        })
      }
      // this throws error that this.setState is not a function
      else {
        this.setState({
          titleIsFixed: false,
          titlePosY: halfWindowHeight - value, // got rid of - 33 here
        })
      }
      // vertical offset of hamburger icon from top when fixed is 33px
      if (value >= halfWindowHeight - 33 || window.innerWidth <= 768) {
        // necessary to set menu position here when fixed
        this.setState({
          menuIsFixed: true,
          menuPosY: window.innerWidth <= 768 ? 0 : 33,
        })
      } else {
        this.setState({
          menuIsFixed: false,
          menuPosY: halfWindowHeight - value,
        })
      }

      /* --- CASE STUDY TEMPLATE COLOR INVERSION --- */
      if (this.props.pageTitle === 'CaseStudyTemplate') {
        // if scroll is past window height (hero) turn back to blue
        // 105 = approximate title height 50px + 33px offset when fixed
        if (value >= winHeight - 83 || !this.props.isInverted) {
          this.setState({ isInverted: false })
        }
        if (value < winHeight - 83 && this.props.isInverted) {
          this.setState({ isInverted: true })
        }
      } else if (this.props.pageTitle === 'CaseStudiesPage') {
        /* --- CASE STUDY PAGE COLOR INVERSION --- */

        if (value >= winHeight - 83 && this.props.isInverted) {
          this.setState({ isInverted: true })
        }
        if (
          value < winHeight - 83 ||
          !this.props.isInverted ||
          value >= docHeight - winHeight - 83
        ) {
          this.setState({ isInverted: false })
        }
      }
    }
  }

  render() {
    let verticalTitle = cx('vertical15Finches', {
      fixed: this.state.titleIsFixed,
      white: this.state.isInverted,
    })

    return (
      <div className={styles.header} style={{ position: 'relative' }}>
        <VerticalTitle
          icon={finchHead}
          isFixed={this.state.titleIsFixed}
          titlePosY={this.state.titlePosY}
          menuOpen={false}
          isInverted={this.state.isInverted}
        />
        <HamburgerIcon
          isFixed={this.state.menuIsFixed}
          menuPosY={this.state.menuPosY}
          titlePosY={this.state.titlePosY}
          isInverted={this.state.isInverted}
        />
      </div>
    )
  }
}

export default Header
