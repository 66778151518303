import React from 'react'
import { Link } from 'gatsby'
import styles from './hamburger-icon-styles.module.scss'
import classNames from 'classnames/bind'
import Menu from './menu'
import MenuItem from './menuItem'
import MenuButton from './menuButton'

import rightArrowPeach from '../../images/right-arrow-peach.png'
import rightArrowWhite from '../../images/right-arrow-white.png'
import RightArrowBlue from '../../images/right-arrow-blue.svg'

let cx = classNames.bind(styles)

const HamburgerIcon = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      arrowURL: rightArrowPeach,
    }
    //console.log('default menuOpen is ' + this.state.menuOpen)
    //this.toggleScrollLock = this.toggleScrollLock.bind(this)
    if (typeof document !== 'undefined') {
      document.body.style.overflow = ''
    }
  }

  toggleScrollLock() {
    // logic is backwards because not accessing updated state?
    if (!this.state.menuOpen) {
      if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden'
      }
    } else {
      if (typeof document !== 'undefined') {
        document.body.style.overflow = ''
      }
    }
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen })

    this.toggleScrollLock()
  }

  handleLinkClick() {
    this.setState({ menuOpen: false })
  }

  mouseEnterHoverBox() {
    this.setState({ arrowURL: rightArrowWhite })
  }

  mouseLeaveHoverBox() {
    this.setState({ arrowURL: rightArrowPeach })
  }

  render() {
    //console.log('in render after set state : ' + this.state.menuOpen)
    //console.log('menu pos y is ' + this.props.menuPosY)
    let containerClass = cx('container', {
      open: this.state.menuOpen,
      fixed: this.props.isFixed,
    })
    var containerStyle

    if (this.state.menuOpen) {
      if (this.state.isFixed) {
        containerStyle = {
          //transform: 'scale(0.45)',
          top: 0,
        }
      } else {
        containerStyle = {
          //transform: 'scale(0.45)',
          // if menuPosY === 0 i.e. no scrolling - don't set top val
          top: this.props.menuPosY !== 0 ? this.props.menuPosY + 'px' : '',
        }
      }
    } else {
      containerStyle = {
        //transform: 'scale(0.45)',
      }
    }
    const menuItems = this.props.menu.map(item => {
      return (
        <MenuItem
          key={item.id}
          delay={`${item.id * 0.1}s`}
          onClick={() => {
            this.handleLinkClick()
          }}
          link={item.link}
        >
          {item.val}
        </MenuItem>
      )
    })
    return (
      <div>
        <div className={containerClass} style={containerStyle}>
          {/*}<img
            className={styles.menuIcon}
            src="../images/menu-bar.png"
            alt=""
          />*/}
          <MenuButton
            open={this.state.menuOpen}
            isInverted={this.props.isInverted}
            onClick={() => this.handleMenuClick()}
          />
        </div>
        <Menu
          open={this.state.menuOpen}
          titlePosY={this.props.titlePosY}
          titleIsFixed={this.props.isFixed}
        >
          {menuItems}
          <div
            className={styles.letsBuildLink}
            onMouseEnter={this.mouseEnterHoverBox.bind(this)}
            onMouseLeave={this.mouseLeaveHoverBox.bind(this)}
          >
            <Link to="/contact-form/">
              Let's build something awesome together!
            </Link>
            <RightArrowBlue alt="arrow" />
          </div>
        </Menu>
      </div>
    )
  }
}

export default HamburgerIcon

{
  /*}<div>
  <div className={styles.container}>
    <MenuButton
      open={this.state.menuOpen}
      onClick={() => this.handleMenuClick()}
      color="white"
    />
  </div>
  <Menu open={this.state.menuOpen}>{menuItems}</Menu>
</div>*/
}
