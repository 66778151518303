import React from 'react'
import styles from './menu-styles.module.scss'
import classNames from 'classnames/bind'
import VerticalTitle from '../verticalTitle'
import MenuItem from './menuItem'
import MenuButton from './menuButton'
import finchIcon from '../../images/finches/0Finch-drawing-straight.png'
import finchHead from '../../images/finch-head.png'

let cx = classNames.bind(styles)

const Menu = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false,
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open })
    }
  }

  render() {
    let containerClass = cx('container', {
      menuOpen: this.state.open,
    })
    return (
      <div className={containerClass}>
        {this.state.open && (
          <VerticalTitle
            icon={finchHead}
            menuOpen={this.state.open}
            titlePosY={this.props.titlePosY}
            isFixed={this.props.isFixed}
          />
        )}
        {this.state.open && (
          <div className={styles.flexContainer}>
            <div className={styles.menuList}>{this.props.children}</div>
            <div className={styles.icon}>
              <img src={finchIcon} alt="" />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Menu
