import React from 'react'
import styles from '../styles/components/scroll-progress-bar.module.scss'

const ScrollProgressBar = class extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialScrollPercentage: 0,
      scrollPercentage: 0,
      isMobile: false,
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll() {
    let supportPageOffset = window.pageXOffset !== undefined
    let isCSS1Compat = (document.compatMode || '') === 'CSS1Compat'
    let scrollPx = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop

    const winHeight =
      this.props.article.current.getBoundingClientRect().bottom -
      window.innerHeight -
      this.props.article.current.getBoundingClientRect().top
    const scrolled = `${(scrollPx / winHeight) * 100}%`
    this.setState({ scrollPercentage: scrolled })
  }

  render() {
    const containerStyle = {
      height: '6px',
      width: '100vw',
    }

    return (
      <div className={styles.container} style={containerStyle}>
        <div
          style={{
            height: '6px',
            // minWidth: this.state.isMobile ? '60px' : '115px',
            width: this.state.scrollPercentage,
          }}
        />
      </div>
    )
  }
}

export default ScrollProgressBar
