import React from 'react'
import { Link } from 'gatsby'
import styles from '../styles/components/vertical-title.module.scss'
import classnames from 'classnames/bind'

let cx = classnames.bind(styles)

const VerticalTitle = props => {
  const { title, isFixed, titlePosY, menuOpen, isInverted, icon } = props
  let verticalTitleClass = cx(
    'verticalTitle',
    { fixed: isFixed },
    { white: menuOpen || isInverted }
  )
  let verticalTitleStyle
  if (menuOpen) {
    verticalTitleStyle = { top: titlePosY !== 0 ? titlePosY + 'px' : 50 }
  }

  let iconClass = cx(
    'icon',
    { fixed: isFixed },
    { white: menuOpen || isInverted }
  )
  let iconStyle
  if (menuOpen) {
    iconStyle = { top: titlePosY !== 0 ? titlePosY + 'px' : 50 }
  }

  return (
    <Link to="/">
      <div className={verticalTitleClass} style={verticalTitleStyle}>
        {title && title}
        {icon && (
          <img
            className={styles.icon}
            src={icon}
            alt={title ? title : '15 Finches'}
          />
        )}
      </div>
      {icon && (
        <div className={iconClass}>
          <img src={icon} alt={title ? title : '15 Finches'} />
        </div>
      )}
    </Link>
  )
}

export default VerticalTitle
