import React from 'react'
import style from './cursor.module.css'
import CursorFx from './cursor-fx.js'
import { isBrowser, isIE, isEdge } from 'react-device-detect'

class Cursor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      cursor: null,
    }
  }

  setUpCursor() {
    this.setState({
      cursor: new CursorFx(document.querySelector('.cursor')),
    })

    var aTags = document.querySelectorAll('a')
    for (var i = aTags.length - 1; i >= 0; i--) {
      if (aTags[i].getAttribute('data-hover') !== 'expand-white') {
        aTags[i].setAttribute('data-hover', 'expand')
      }
    }

    var dataHoverExpandTags = document.querySelectorAll('[data-hover=expand]')
    dataHoverExpandTags.forEach(link => {
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterDataHover()
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.leaveDataHover()
      )
      link.addEventListener('click', () => this.state.cursor.clickDataHover())
    })

    var dataHoverExpandWhiteTags = document.querySelectorAll(
      '[data-hover=expand-white]'
    )
    dataHoverExpandWhiteTags.forEach(link => {
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterDataHover('white')
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.leaveDataHover('white')
      )
      link.addEventListener('click', () => this.state.cursor.clickDataHover())
    })

    var dataHoverExpandFilledTags = document.querySelectorAll(
      '[data-hover=expand-filled]'
    )
    dataHoverExpandFilledTags.forEach(link => {
      var dataHoverText = link.getAttribute('data-filled-text')
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterLargeFilled(dataHoverText)
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.leaveLargeFilled()
      )
      link.addEventListener('click', () => this.state.cursor.clickLargeFilled())
    })

    var dataHoverTextTags = document.querySelectorAll(
      '[data-hover=expand-text]'
    )
    dataHoverTextTags.forEach(link => {
      var dataHoverText = link.getAttribute('data-filled-text')
      var dataHoverColor = link.getAttribute('data-filled-color')
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterLargeText(dataHoverText, dataHoverColor)
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.leaveLargeText()
      )
      link.addEventListener('click', () => this.state.cursor.clickLargeFilled())
    })

    var dataHoverDisappearOnClick = document.querySelectorAll(
      '[data-hover=disappear-on-click]'
    )
    dataHoverDisappearOnClick.forEach(link => {
      var dataHoverText = link.getAttribute('data-filled-text')
      var dataHoverColor = link.getAttribute('data-filled-color')
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterLargeText(dataHoverText, dataHoverColor)
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.leaveLargeText()
      )
      link.addEventListener('click', () => this.state.cursor.disappearOnClick())
    })

    var dataHoverDisappearOnLeave = document.querySelectorAll(
      '[data-hover=disappear-on-leave]'
    )
    dataHoverDisappearOnLeave.forEach(link => {
      var dataHoverText = link.getAttribute('data-filled-text')
      var dataHoverColor = link.getAttribute('data-filled-color')
      link.addEventListener('mouseenter', () =>
        this.state.cursor.enterLargeText(dataHoverText, dataHoverColor)
      )
      link.addEventListener('mouseleave', () =>
        this.state.cursor.disappearOnLeave()
      )
      link.addEventListener('click', () => this.state.cursor.clickLargeFilled())
    })
  }

  componentDidMount() {
    if (isIE || isEdge) {
      document.body.style.cursor = 'auto'
      document.querySelector('.cursor').style.visibility = 'hidden'
    } else if (isBrowser) {
      this.setUpCursor()
    }
  }

  handleOnMouseMove(e) {
    this.state.cursor.render()
  }

  renderContent() {
    if (isBrowser) {
      return (
        <div className={`cursor`} onMouseMove={e => this.handleOnMouseMove(e)}>
          <div
            className={`cursor__inner--circle ${style.generalCursor} ${style.cursorInnerCircle}`}
          />
          <div
            className={`cursor__inner--dot ${style.generalCursor} ${style.cursorInnerDot}`}
          >
            <div className={style.textInFilled}></div>
          </div>
        </div>
      )
    } else {
      return <div></div>
    }
  }

  render() {
    return this.renderContent()
  }
}

export default Cursor
